export default class ImageUploadGallery {
	constructor(options) {
		this.ui = {
			gallery: $('.image-gallery'),
			nav:     $('.js-gallery-nav'),
			images:  $('.js-gallery-image'),
			input:   $('.js-gallery-image-input'),
			current: $('.js-gallery-image-current'),
			modal:   $('#modal-gallery'),
			preview: options.preview || false,
			previewContainer: options.previewContainer || false,
		};

		this.cropper = options.cropper;
		this.cropper.callback = () => {
			this.deselectImages();
		}

		this.lastChosenImage = 'user_crop';

		this.bindEventHandlers();
	}

	bindEventHandlers() {
		this.ui.nav.on('click', '.btn', this.onNavigate.bind(this));
		this.ui.images.on('click', this.onSelectImage.bind(this));
		this.cropper.ui.cropResult.on('click', this.reselectCropped.bind(this));
		this.ui.preview.on('load', this.onUpdatePreview.bind(this));
		this.ui.modal.on('shown.bs.modal', this.onShowModal.bind(this))
	}

	onNavigate(e) {
		e.preventDefault();

		// Get the gallery group
		const galleryGroup = $($(e.currentTarget).data('target'));

		console.log('ImageUploadGallery.onNavigate()', galleryGroup);

		// Stop if doesn't exist
		if(!galleryGroup.length) return;

		// Scroll the gallery
		this.scrollTo(galleryGroup, 500);
	}

	scrollTo(el, speed) {
		const galleryContainer = $(this.ui.gallery).parents(".modal-body") ?? $(this.ui.gallery);
		galleryContainer.animate({
			scrollTop: el.position().top + this.ui.gallery.scrollTop() - this.ui.nav.outerHeight(true)
		}, speed);
	}

	onSelectImage(e) {
		this.deselectImages(true);

		const selectedEl = $(e.currentTarget);
		const id = selectedEl.attr('data-id');
		
		console.log("ImageUploadGallery.onSelectImage()", id);
		
		this.ui.input.val(id);
		this.togglePreview(selectedEl);
		this.lastChosenImage = 'gallery';

		const images = $("img[data-id="+id+"]"); // This ensures both the preview and the gallery have .selected
		images.addClass('selected'); // Needs to happen after togglePreview()

		this.cropper.clearErrorMsg();
	}

	deselectImages(deselectCropped) {
		console.log("ImageUploadGallery.deselectImages()", deselectCropped);

		if(deselectCropped){ 
			this.cropper.ui.cropResult.removeClass('selected');
			this.cropper.ui.cropResultContainer.removeClass('selected');
		}

		this.ui.images.removeClass('selected');
		this.ui.input.val('');
	}

	// If the user uploads an image then selects a gallery image, this is how to change back without a reupload
	reselectCropped(e) {
		console.log("ImageUploadGallery.reselectCropped()");
		this.deselectImages();
		$(e.currentTarget).addClass('selected');
		this.lastChosenImage = 'user_crop';
	}

	togglePreview(selectedEl) {
		if(!this.ui.preview) return;
		const id = selectedEl.length ? selectedEl.data('id') : null;

		if(id == this.ui.current.data('id')) {
			console.log("ImageUploadGallery.togglePreview()", "hide");
			this.ui.previewContainer.collapse("hide");
			this.ui.preview.attr("src", this.ui.preview.data('spacer') || '');
		} else {
			console.log("ImageUploadGallery.togglePreview()", "show");
			this.ui.preview.attr("src", selectedEl[0].src);
			this.ui.preview.attr("data-id", id);
			this.ui.previewContainer.collapse("show");
		}
	}

	// Remove the data-id attribute if image updated by something else, e.g. crop tool
	onUpdatePreview(e) {
		if(!this.ui.preview) return;
		console.log("ImageUploadGallery.onUpdatePreview");
		if(e.currentTarget.src.startsWith('blob')) {
			this.ui.preview.attr('data-id', null);
		}
	}

	onShowModal(e) {
		console.log("ImageUploadGallery.onShowModal");
		const selectedEl = this.ui.modal.find(".selected");
		if(!selectedEl.length) return;
		this.scrollTo(selectedEl.parents(".row"), 200);
	}
}